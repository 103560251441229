import React from 'react';
import navbarData from "data/navbar"
import Anchor from "components/anchor"


// keeping following json for refrence purpose only, Delete in future.

// {
//     "id": 1,
//     "title": "Home",
//     "link": "/",
//     "subMenu": false,
//     "megaMenu": [
//       {
//         "id": 1.1,
//         "megaTitle": "Homepage Sample 01",
//         "lists": [
//           {
//             "id": "1.1.1",
//             "title": "Business",
//             "link": "/home-business"
//           },
//           {
//             "id": "1.1.2",
//             "title": "Creative Agency",
//             "link": "/home-creative-agency"
//           },
//           {
//             "id": "1.1.3",
//             "title": "Digital Agency",
//             "link": "/home-digital-agency"
//           },
//           {
//             "id": "1.1.4",
//             "title": "Startup",
//             "link": "/home-startup"
//           }
//         ]
//       },
//       {
//         "id": 1.2,
//         "megaTitle": "Homepage Sample 02",
//         "lists": [
//           {
//             "id": "1.2.1",
//             "title": "Design Studio",
//             "link": "/home-design-studio"
//           },
//           {
//             "id": "1.2.2",
//             "title": "Architecture",
//             "link": "/home-architecture"
//           },
//           {
//             "id": "1.2.3",
//             "title": "Authentic Studio",
//             "link": "/home-authentic-studio"
//           }
//         ]
//       },
//       {
//         "id": 1.3,
//         "megaTitle": "Homepage Sample 03",
//         "lists": [
//           {
//             "id": "1.3.1",
//             "title": "Minimal Agency",
//             "link": "/home-minimal-agency"
//           },
//           {
//             "id": "1.3.2",
//             "title": "Freelancer",
//             "link": "/home-freelancer"
//           },
//           {
//             "id": "1.3.3",
//             "title": "Revolutionary",
//             "link": "/home-revolutionary"
//           }
//         ]
//       },
//       {
//         "id": 1.4,
//         "megaTitle": "HOMEPAGE SAMPLE 04",
//         "lists": [
//           {
//             "id": "1.4.1",
//             "title": "Creative Portfolio",
//             "link": "/home-creative-portfolio"
//           },
//           {
//             "id": "1.4.2",
//             "title": "Minimal Portfolio",
//             "link": "/home-minimal-portfolio"
//           },
//           {
//             "id": "1.4.3",
//             "title": "Vertical Slide Portfolio",
//             "link": "/home-vertical-slide-portfolio"
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "id": 2,
//     "title": "Pages",
//     "link": "/",
//     "subMenu": false,
//     "megaMenu": [
//       {
//         "id": 2.1,
//         "megaTitle": "Sample Pages",
//         "lists": [
//           {
//             "id": "2.1.1",
//             "title": "About Us 01",
//             "link": "/about-one"
//           },
//           {
//             "id": "2.1.2",
//             "title": "About Us 02",
//             "link": "/about-two"
//           },
//           {
//             "id": "2.1.3",
//             "title": "About Us 03",
//             "link": "/about-three"
//           },
//           {
//             "id": "2.1.4",
//             "title": "About Us 04",
//             "link": "/about-four"
//           }
//         ]
//       },
//       {
//         "id": 2.2,
//         "megaTitle": "Sample Pages",
//         "lists": [
//           {
//             "id": "2.2.1",
//             "title": "Contact Us Classic",
//             "link": "/contact"
//           },
//           {
//             "id": "2.2.2",
//             "title": "Contact Us Modern",
//             "link": "/contact-us-modern"
//           },
//           {
//             "id": "2.2.3",
//             "title": "Services Classic",
//             "link": "/service-classic"
//           },
//           {
//             "id": "2.2.4",
//             "title": "Services Modern",
//             "link": "/service-modern"
//           }
//         ]
//       },
//       {
//         "id": 2.3,
//         "megaTitle": "Sample Pages",
//         "lists": [
//           {
//             "id": "2.3.1",
//             "title": "Pricing Table",
//             "link": "/pricing-table"
//           },
//           {
//             "id": "2.3.2",
//             "title": "Pricing Table 02",
//             "link": "/pricing-table-two"
//           },
//           {
//             "id": "2.3.5",
//             "title": "Our Team Grid",
//             "link": "/team"
//           },
//           {
//             "id": "2.3.3",
//             "title": "Our Team Carousel",
//             "link": "/team-carousel"
//           }
//         ]
//       },
//       {
//         "id": 2.4,
//         "megaTitle": "Sample Pages",
//         "lists": [
//           {
//             "id": "2.4.1",
//             "title": "Maintenance",
//             "link": "/maintenance"
//           },
//           {
//             "id": "2.4.2",
//             "title": "Career",
//             "link": "/career"
//           },
//           {
//             "id": "2.4.3",
//             "title": "404 Page",
//             "link": "/404"
//           },
//           {
//             "id": "2.4.4",
//             "title": "Coming Soon",
//             "link": "/coming-soon"
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "id": 3,
//     "title": "Portfolios",
//     "link": "/",
//     "subMenu": false,
//     "megaMenu": [
//       {
//         "id": 3.1,
//         "megaTitle": "Portfolio Sample 01",
//         "lists": [
//           {
//             "id": "3.1.1",
//             "title": "Portfolio Grid – Boxed",
//             "link": "/portfolio-grid-boxed"
//           },
//           {
//             "id": "3.1.2",
//             "title": "Portfolio Grid – Wide",
//             "link": "/portfolio-grid-wide"
//           },
//           {
//             "id": "3.1.3",
//             "title": "Portfolio With Caption",
//             "link": "/portfolio"
//           }
//         ]
//       },
//       {
//         "id": 3.2,
//         "megaTitle": "Portfolio Sample 02",
//         "lists": [
//           {
//             "id": "3.2.1",
//             "title": "Portfolio Metro Boxed",
//             "link": "/portfolio-metro-boxed"
//           },
//           {
//             "id": "3.2.2",
//             "title": "Portfolio Metro Wide",
//             "link": "/portfolio-metro-wide"
//           },
//           {
//             "id": "3.2.3",
//             "title": "Portfolio Ajax Loading",
//             "link": "/portfolio-ajax-loading"
//           }
//         ]
//       },
//       {
//         "id": 3.3,
//         "megaTitle": "Portfolio Simple 03",
//         "lists": [
//           {
//             "id": "3.3.1",
//             "title": "Portfolio Masonry – Boxed",
//             "link": "/portfolio-masonry-boxed"
//           },
//           {
//             "id": "3.3.2",
//             "title": "Portfolio Masonry – Wide",
//             "link": "/portfolio-masonry-wide"
//           },
//           {
//             "id": "3.3.3",
//             "title": "Portfolio Filterable",
//             "link": "/portfolio-filterable"
//           }
//         ]
//       },
//       {
//         "id": 3.4,
//         "megaTitle": "Portfolio Details",
//         "lists": [
//           {
//             "id": "3.4.1",
//             "title": "Left Details",
//             "link": "/portfolio-details"
//           },
//           {
//             "id": "3.4.2",
//             "title": "Right Details",
//             "link": "/portfolio-right-details"
//           },
//           {
//             "id": "3.4.3",
//             "title": "Portfolio Details Modern",
//             "link": "/portfolio-details-modern"
//           }
//         ]
//       }
//     ]
//   },
//   {
//     "id": 4,
//     "title": "Blogs",
//     "link": "/",
//     "subMenu": {
//       "lists": [
//         {
//           "id": "4.1",
//           "title": "Blog",
//           "link": "/blog"
//         },
//         {
//           "id": "4.2",
//           "title": "Blog Left Sidebar",
//           "link": "/blog-left-sidebar"
//         },
//         {
//           "id": "4.3",
//           "title": "Blog Right Sidebar",
//           "link": "/blog-right-sidebar"
//         },
//         {
//           "id": "4.4",
//           "title": "Blog Details",
//           "link": "/blog-details"
//         },
//         {
//           "id": "4.5",
//           "title": "Blog Details No Sidebar",
//           "link": "/blog-details-no-sidebar"
//         }
//       ]
//     },
//     "megaMenu": false
//   },
//   {
//     "id": 5,
//     "title": "Elements",
//     "link": "/",
//     "subMenu": false,
//     "megaMenu": [
//       {
//         "id": 5.1,
//         "megaTitle": "ELEMENT GROUP 01",
//         "lists": [
//           {
//             "id": "5.1.1",
//             "title": "Client Logos",
//             "link": "/element/client-logo"
//           },
//           {
//             "id": "5.1.2",
//             "title": "Flexible image slider",
//             "link": "/element/flexible-image-slider"
//           },
//           {
//             "id": "5.1.3",
//             "title": "Carousel Sliders",
//             "link": "/element/carousel-slider"
//           },
//           {
//             "id": "5.1.4",
//             "title": "Team Member",
//             "link": "/element/team-member"
//           },
//           {
//             "id": "5.1.5",
//             "title": "Pricing Tables",
//             "link": "/element/pricing-table"
//           },
//           {
//             "id": "5.1.6",
//             "title": "Call to action banners",
//             "link": "/element/call-to-action"
//           },
//           {
//             "id": "5.1.7",
//             "title": "Instagram Feed",
//             "link": "/element/instagram-feed"
//           }
//         ]
//       },
//       {
//         "id": 5.2,
//         "megaTitle": "ELEMENT GROUP 02",
//         "lists": [
//           {
//             "id": "5.2.1",
//             "title": "Countdown Clocks",
//             "link": "/element/countdown-clock"
//           },
//           {
//             "id": "5.2.2",
//             "title": "Counters",
//             "link": "/element/counter"
//           },
//           {
//             "id": "5.2.3",
//             "title": "Progress Charts",
//             "link": "/element/progressbar"
//           },
//           {
//             "id": "5.2.4",
//             "title": "Icon Boxes",
//             "link": "/element/icon-box"
//           },
//           {
//             "id": "5.2.5",
//             "title": "Testimonials",
//             "link": "/element/testimonial"
//           },
//           {
//             "id": "5.2.6",
//             "title": "Tabs",
//             "link": "/element/tab"
//           }
//         ]
//       },
//       {
//         "id": 5.3,
//         "megaTitle": "ELEMENT GROUP 03",
//         "lists": [
//           {
//             "id": "5.3.1",
//             "title": "Maps",
//             "link": "/element/map"
//           },
//           {
//             "id": "5.3.2",
//             "title": "Social Icons",
//             "link": "/element/social-icon"
//           },
//           {
//             "id": "5.3.3",
//             "title": "Gradation",
//             "link": "/element/gradation"
//           },
//           {
//             "id": "5.3.4",
//             "title": "Lightweight Slider",
//             "link": "/element/lightweight-slider"
//           },
//           {
//             "id": "5.3.5",
//             "title": "Gallery",
//             "link": "/element/gallery"
//           },
//           {
//             "id": "5.3.6",
//             "title": "Popup Video",
//             "link": "/element/popup-video"
//           }
//         ]
//       },
//       {
//         "id": 5.4,
//         "megaTitle": "ELEMENT GROUP 04",
//         "lists": [
//           {
//             "id": "5.4.1",
//             "title": "Accordion & toggle",
//             "link": "/element/accordion"
//           },
//           {
//             "id": "5.4.2",
//             "title": "Buttons",
//             "link": "/element/button"
//           },
//           {
//             "id": "5.4.3",
//             "title": "Typed Text",
//             "link": "/element/typed-text"
//           },
//           {
//             "id": "5.4.4",
//             "title": "Lists",
//             "link": "/element/list"
//           },
//           {
//             "id": "5.4.5",
//             "title": "Message Boxes",
//             "link": "/element/message-box"
//           },
//           {
//             "id": "5.4.7",
//             "title": "Forms",
//             "link": "/element/form"
//           }
//         ]
//       }
//     ]
//   }

const NavbarItem = () => {
    return (
        navbarData.map(navbar => (
            <li key={navbar.id}
                className={`label-1 ${navbar.megaMenu || navbar.subMenu ? 'with--drop' : ''} ${navbar.megaMenu ? 'slide--megamenu' : ''} ${navbar.subMenu ? 'slide-dropdown' : ''}`}>
                <Anchor path={`${process.env.PUBLIC_URL + navbar.link}`}><span>{navbar.title}</span></Anchor>
                {navbar.subMenu ? (
                    <ul className="dropdown__menu">
                        {navbar.subMenu.lists.map(subItem => (
                            <li key={subItem.id}>
                                <Anchor path={`${process.env.PUBLIC_URL + subItem.link}`}>
                                    <span>{subItem.title}</span>
                                </Anchor>
                            </li>
                        ))}
                    </ul>
                ):null}
                {navbar.megaMenu ? (
                    <div className="mega__width--fullscreen">
                        <div className="container">
                            <div className="row">
                                {navbar.megaMenu.map(megaItem => (
                                    <div key={megaItem.id} className="col-lg-3">
                                        <ul className="mega__list">
                                            <li className="mega--title">{megaItem.megaTitle}</li>

                                            {megaItem.lists.map(list => (
                                                <li key={list.id}>
                                                    <Anchor path={`${process.env.PUBLIC_URL + list.link}`}>
                                                        <span>{list.title}</span>
                                                    </Anchor>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : null}
            </li>
        ))
    );
};

export default NavbarItem;